import {useState} from 'react'
import { Form, Tabs, Checkbox, Row, Col, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { Role } from '../../../models/role';
import {
  appSelector,
  AppSelectorState,
} from '../../../store/selectors/appSelector';
import { AppState } from '../../../store/state.model';
import { Application } from '../../../models/application';
import styles from './role.module.scss';
const _superAdmin: string = 'a5f3915b-0abc-4695-a9ac-044637624d47';

export const AppRoles = () => {
  const [userEnviroment, setUserEnviroment] = useState<"Test" | "Prod">("Test")

  // global state
  const { user, appSelectionList } = useSelector<AppState, AppSelectorState>(
    appSelector
  );
  const roles = [Role.SuperAdmin];
  const isSuper =
    user && user.roles.some((r: any) => roles.includes(r)) ? true : false;

  const tabOrder = ['craftOS Service', 'CraftOS Team Management', 'CraftOS Service Planner', 'Buildability API', 'Planner Sandwich API', 'Heatflow API'];

  const generateTabs = (apps: Application[]): {label: string, key: string, children: any }[] => {
    // Hide apps that does not have roles with description Prod or Test
    const filteredApps = apps.filter(({ appRoles }) =>
      appRoles.some(({ description }) => ['Prod', 'Test'].includes(description))
    ).sort((app1, app2) => {
      const app1order = tabOrder.includes(app1.displayName) ? tabOrder.indexOf(app1.displayName) : 100
      const app2order = tabOrder.includes(app2.displayName) ? tabOrder.indexOf(app2.displayName) : 100
      return app1order - app2order
    });

    return filteredApps.map(app => {
      const { displayName, appRoles, id } = app
      const sortedAppRoles = [...appRoles].sort((a, b) => a.displayName.localeCompare(b.displayName))

      return {
        // Important, this removes the lazyloading of the tab.
        forceRender: true,
        label: displayName, 
        key: id, 
        children: <Row gutter={[16, 8]} >
          {sortedAppRoles.map(role => 
            <Col hidden={role.description !== userEnviroment} span={8}>
              <Checkbox 
                value={role.id} 
                disabled={role.id === _superAdmin && !isSuper} 
                key={role.id}>{role.displayName} ({role.description})
              </Checkbox>
            </Col>
          )}
        </Row>
      }
    })
  }

  return (
    <div className={styles.role}>
      <p>Role Manegement</p>
      <div className={styles.roleEnviroment}>
        <span>Set role enviroment:</span>
        <Radio.Group onChange={e => setUserEnviroment(e.target.value)} value={userEnviroment}>
          <Radio value={"Prod"}>Production</Radio>
          <Radio value={"Test"}>Test</Radio>
        </Radio.Group>
      </div> 
      <Form.Item name='roles'>
        <Checkbox.Group>
          <Tabs style={{ width: 950 }} items={generateTabs(appSelectionList)} /> 
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
};
